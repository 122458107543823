
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  computed,
  watch,
  nextTick,
} from "vue";
import {
  addPatientPayer,
  getPatientPayer,
  deletePatientPayer,
  updatePatientPayer,
  checkPatientPayerPriority,
} from "@/api/patientPayer.api";
import { searchDictInsuranceCompanys } from "@/api/code-master-insurance-company.api";
import { PatientInsurance, PatientPayer } from "@/modules/patientPayer/patientPayer.model";
import { getOrganization } from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Multiselect from "@vueform/multiselect";
import { translateDate } from "@/modules/utility/date-utility";
import useVuelidate from "@vuelidate/core";
import { helpers, maxLength, required } from "@vuelidate/validators";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { getDictionary } from "@/api/dictionaries.api";
import { getPatient } from "@/api/patient.api";
import Country from "@/components/ABilling/CounrtyComponent.vue";
import State from "@/components/ABilling/StateComponent.vue";
import DecimalInput from "@/components/ABilling/DecimalInput.vue";
import PhoneNumberComponent from "@/components/ABilling/PhoneNumberComponent.vue";

export interface Data {
  isDirty: boolean;
  modal: boolean;
  previousRoute?: any;
  isLoaded: boolean;
  previousPatientPayer?: PatientPayer;
  patientPayer?: PatientPayer;
  createNewMode: boolean;
  patient?: any;
  checkResults?: any;
  dict: {
    patientSex: any[];
    patientRelationshipToSubscriber: any[];
    patientPayerPriority: any[];
    waystarList: PatientInsurance[];
    statuses: {
      code: string;
      name: string;
    }[];
    boolListString: { code: string; name: string }[];
  };
}
export default defineComponent({
  name: "Patient Payer Page",
  components: {
    Multiselect,
    DecimalInput,
    State,
    Country,
    PhoneNumberComponent,
  },
  props: [
    "patientPayerId",
    "patientId",
    "loadFromCache",
    "backToPayer",
    "query",
  ],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      (vm as any).data.previousRoute = from;
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.data.isDirty) {
      let text = "Are you sure you want to leave without saving changes?";
      Swal.fire({
        title: text,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        allowOutsideClick: false,
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1 right-gap",
          confirmButton: "order-2",
          denyButton: "order-3",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          next();
        } else if (result.isDenied) {
          next(false);
        }
      });
    } else {
      next();
    }
  },
  setup(props) {
    let organizationId = ref<string | null>("");
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const breadcrumbPath = decodeURIComponent(
      String(route.query?.breadcrumb ?? "back")
    );
    let data = reactive<Data>({
      isDirty: false,
      modal: false,
      isLoaded: false,
      patient: undefined,
      patientPayer: undefined,
      createNewMode: true,
      dict: {
        patientSex: [],
        patientRelationshipToSubscriber: [],
        statuses: [],
        patientPayerPriority: [],
        waystarList: [],
        boolListString: [
          { code: "Y", name: "Yes" },
          { code: "N", name: "No" },
        ],
      },
    });

    watch(
      () => data.patientPayer,
      (currentValue, oldValue) => {
        if (
          currentValue &&
          data.previousPatientPayer &&
          JSON.stringify(currentValue) !=
            JSON.stringify(data.previousPatientPayer)
        ) {
          data.isDirty = true;
        }
      },
      { deep: true }
    );

    watch(
      () => props.patientPayerId,
      () => {
        router.go(0);
      }
    );

    const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im // eslint-disable-line
    const billingReq = (param) =>
      helpers.withParams(
        { type: "billingReq", value: param },
        (value) => helpers.req(value) || !param.patientPayer?.billing
      );
    const phoneReq = (param) =>
      helpers.withParams(
        { type: "phoneReq", value: param },
        (value) =>
          (helpers.req(value) && (value as string).match(phoneRegex)) ||
          !param.patientPayer?.billing
      );

    const patientPayerRules = {
      patientPayer: {
        payerId: { required: helpers.withMessage("Required", required) },
        name: { required: helpers.withMessage("Required", required) },
        insurancePhone: { phoneReq },
        responsibility: { required: helpers.withMessage("Required", required) },
        subscriberIdNumber: {
          required: helpers.withMessage("Required", required),
        },
        patientRelationshipToSubscriber: {
          required: helpers.withMessage("Required", required),
        },
        subscriberFirstName: {
          required: helpers.withMessage("Required", required),
        },
        subscriberLastName: {
          required: helpers.withMessage("Required", required),
        },
        subscriberCountry: {
           required: helpers.withMessage("Required", required),
        },
        // subscriberDateOfBirth: {
        //   required: helpers.withMessage("Required", required),
        // },
        // subscriberSex: { required: helpers.withMessage("Required", required) },
        // subscriberAddress1: {
        //   required: helpers.withMessage("Required", required),
        // },
        // subscriberCity: { required: helpers.withMessage("Required", required) },
        // subscriberState: {
        //   required: helpers.withMessage("Required", required),
        // },
        // subscriberZip: {
        //   required: helpers.withMessage("Required", required),
        //   maxLength: maxLength(15),
        // },
      },
    };

    let v$ = useVuelidate(patientPayerRules, data as never);

    onMounted(async () => {
      setCurrentPageTitle("Insurance");
      organizationId.value = getOrganization();
      data.dict.patientPayerPriority = store.getters.allPatientPayerPriority;
      data.dict.patientSex = store.getters.allPatientSex;
      data.dict.patientRelationshipToSubscriber = await getDictionary(10, "");
      data.dict.waystarList = await searchDictInsuranceCompanys({ search: "" });
      data.dict.statuses = [
        { code: "active", name: "Active" },
        { code: "inactive", name: "Inactive" },
      ];
      if (props.patientPayerId) {
        data.patientPayer = await getPatientPayer(props.patientPayerId);
        data.createNewMode = false;
      } else {
        data.createNewMode = true;
        data.patientPayer = {
          id: null,
          patientId: props.patientId,
          releaseOfInformation: "Y",
          assignmentOfBenefits: "Y",
          name: "",
          payerId: "",
          subscriberState: null,
          subscriberCountry: null,
        };
      }

      //US default
      if (!data.patientPayer.subscriberCountry) {
        data.patientPayer.subscriberCountry = "1";
      }

      if (
        window.localStorage.getItem("AddInsurance") !== null &&
        route.query.loadFromCache
      ) {
        const payer = JSON.parse(
          window.localStorage.getItem("AddInsurance") as string
        );
        data.patientPayer = payer;
      }
      data.isLoaded = true;
      data.patient = await getPatient(props.patientId);
      data.previousPatientPayer = JSON.parse(JSON.stringify(data.patientPayer));
    });

    async function cancel() {
      if (route.query.backToPayer != undefined) {
        router.push({
          path:
            "/patientAccounts/patient/" +
            props.patientId +
            "/patientInsurance/" +
            route.query.backToPayer,
          query: { loadFromCache: "true" },
        });
        return;
      }
      if (data.previousRoute && data.previousRoute.fullPath != "/") {
        router.push(data.previousRoute);
      } else {
        router.push({
          path: "/patientAccounts/patient/" + props.patientId + "/patientInsurances",
        });
      }
    }

    async function addItem() {
      const result = await v$.value.patientPayer.$validate();
      if (result) {
        const checkResults = await checkPatientPayerPriority(data.patientPayer);
        if (checkResults.error) {
          data.checkResults = checkResults;
          data.modal = true;
          return;
        }
        const patientPayerId = await addPatientPayer(data.patientPayer);
        data.previousPatientPayer = JSON.parse(
          JSON.stringify(data.patientPayer)
        );
        window.localStorage.removeItem("AddInsurance");
        if (patientPayerId) {
          data.isDirty = false;
          router.push({
            path: "/patientAccounts/patient/" + data.patient.id + "/patientInsurances",
          });
          //data.patientPayer = await getPatientPayer(patientPayerId.data);
          //data.createNewMode = false;
        }
      }
    }

    function addOneDay(dateString) {
      if (dateString) {
        let date = new Date(dateString);
        date.setDate(date.getDate());
        return date.toISOString().split('T')[0];
      }
    }

    async function updateItem() {
      const result = await v$.value.patientPayer.$validate();
      if (result) {
        const checkResults = await checkPatientPayerPriority(data.patientPayer);
        if (checkResults.error) {
          data.checkResults = checkResults;
          data.modal = true;
          return;
        }

        if (data.isDirty) {
          Swal.fire({
            title: "Would you like to apply these changes to all Open Claims?",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            allowOutsideClick: false,
          }).then(async (result) => {
            if (result.isConfirmed && data.patientPayer) {
              data.patientPayer.updateClaimsEvent = true;
            } else if (data.patientPayer) {
              data.patientPayer.updateClaimsEvent = false;
            }
            await updatePatientPayer(data.patientPayer);
            data.previousPatientPayer = JSON.parse(
              JSON.stringify(data.patientPayer)
            );

            data.isDirty = false;

            router.back();
          });
        }
        else {
          router.back();
          return;
        }
        
      }
    }

    async function deleteItem() {
      await deletePatientPayer(data.patientPayer);
    }
    function payerIdChanged(payerId: string) {
      if (data.patientPayer) {
        const item = data.dict.waystarList.find((dict) => {
          return dict.payerId == payerId;
        });
        if (item) {
          data.patientPayer.name = item.name;
          data.patientPayer.insuranceCompanyId = item.id;
        }
      }
    }
    function payerNameChanged(payerName: string) {
      if (data.patientPayer) {
        const item = data.dict.waystarList.find((dict) => {
          return dict.name == payerName;
        });
        if (item) {
          data.patientPayer.payerId = item.payerId;
          data.patientPayer.insuranceCompanyId = item.id;
        }
      }
    }

    async function closeCancel() {
      //if (data.createNewMode)
      //  data.patientPayer = await getPatientPayer(data.patientPayer?.id);
    }

    async function sameAsPatientChanged() {
      if (data.patientPayer) {
        if (data.patientPayer?.sameAsPatient) {
          const patient = data.patient;
          if (patient) {
            data.patientPayer.subscriberAddress1 = patient.address;
            data.patientPayer.subscriberAddress2 = patient.address2;
            data.patientPayer.subscriberCity = patient.city;
            data.patientPayer.subscriberCountry = patient.country;
            await nextTick();
            data.patientPayer.subscriberState = patient.state;
            data.patientPayer.subscriberZip = patient.zip.toString();
          }
        } else {
          data.patientPayer.subscriberAddress1 = undefined;
          data.patientPayer.subscriberAddress2 = undefined;
          data.patientPayer.subscriberCity = undefined;
          data.patientPayer.subscriberCity = undefined;
          data.patientPayer.subscriberCountry = undefined;
          await nextTick();
          data.patientPayer.subscriberState = undefined;
          data.patientPayer.subscriberZip = undefined;
        }
      }
    }

    async function relationshipChanged() {
      if (data.patientPayer) {
        if (data.patientPayer?.patientRelationshipToSubscriber == "18") {
          const patient = data.patient;
          if (patient) {
            data.patientPayer.subscriberFirstName = patient.firstName;
            data.patientPayer.subscriberMiddleInitial = patient.middleName;
            data.patientPayer.subscriberLastName = patient.lastName;
            data.patientPayer.subscriberDateOfBirth = patient.dob;
            data.patientPayer.subscriberSex = patient.sex;

            data.patientPayer.subscriberAddress1 = patient.address;
            data.patientPayer.subscriberAddress2 = patient.address2;
            data.patientPayer.subscriberCity = patient.city;
            data.patientPayer.subscriberCountry = patient.country;
            await nextTick();
            data.patientPayer.subscriberState = patient.state;
            data.patientPayer.subscriberZip = patient.zip.toString();

            data.patientPayer.sameAsPatient = true;
          }
        } else {
          data.patientPayer.subscriberFirstName = "";
          data.patientPayer.subscriberMiddleInitial = "";
          data.patientPayer.subscriberLastName = "";
          data.patientPayer.subscriberDateOfBirth = undefined;
          data.patientPayer.subscriberSex = undefined;

          data.patientPayer.subscriberAddress1 = undefined;
          data.patientPayer.subscriberAddress2 = undefined;
          data.patientPayer.subscriberCity = undefined;
          data.patientPayer.subscriberCountry = undefined;
          await nextTick();
          data.patientPayer.subscriberState = undefined;
          data.patientPayer.subscriberZip = undefined;

          data.patientPayer.sameAsPatient = false;
        }
      }
    }

    const maxStartDate = computed(() => {
      return translateDate(data.patientPayer?.endDate);
    });

    const minEndDate = computed(() => {
      return translateDate(data.patientPayer?.effectiveDate);
    });

    function selectItem(item) {
      data.modal = false;
      window.localStorage.setItem(
        "AddInsurance",
        JSON.stringify(data.patientPayer)
      );
      router.push({
        path: item.id,
        query: { backToPayer: props.patientPayerId },
      });
      //let routeData = router.resolve({
      //  path: "/" + props.patientId + "/patientInsurance/" + item.id,
      //});
      //window.open(routeData.href, "_blank");
    }

    const ifRelationshipSelf = computed(() => {
      return data.patientPayer?.patientRelationshipToSubscriber == "18";
    });

    return {
      organizationId,
      data,
      addItem,
      addOneDay,
      updateItem,
      deleteItem,
      cancel,
      payerIdChanged,
      payerNameChanged,
      closeCancel,
      relationshipChanged,
      sameAsPatientChanged,
      minEndDate,
      maxStartDate,
      selectItem,
      ifRelationshipSelf,
      v$,
    };
  },
});
